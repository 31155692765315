import { CommandBar, Link, List, MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import LoadingContainer from '../common/loading-section';
import { RequestLimitIncreaseInSentenceLink } from '../common/request-limit-increase-link';
import { getCustomTheme } from '../common/themes/selectors';
import commonMessages from '../language/common-messages';
import { CoreQuotaStatus } from '../redux/selectors/core-limit-selectors';
import { Routes } from '../utils/routes';
import { CreateLabFlyoutContainer } from './create-lab/create-lab-flyout-container';
import VNext_CreateLabFlyoutContainer from './create-lab/create-lab-flyout-container-vnext';
import DeleteLabDialog from './delete-lab-dialog';
import { LabListIntroduction } from './introduction';
import { LabCard, LabErrorBanner } from './lab-card';
import { messages as labListMessages } from './lab-list-messages';
import NoLabsView from './no-labs-view';
import moment from 'moment';
import LinkedLmsUpdateErrors from '../common/linked-lms-update-errors';
import ErrorCode from '../common/error-codes';
import { LabServicesModels } from 'lab-services';
import { RetirementNotification } from './retirement-notification';
import './lab-list.css';
import LabAccessDropdown from './lab-access-dropdown';
const messages = defineMessages({
    newLab: {
        id: 'LabListNewLab',
        defaultMessage: 'New lab',
        description: 'Text on a button to open New lab flyout.',
    },
});
export const LabList = (props) => {
    const { labListViewModel, labAccessType, clearError, returnToLabList, loadDashboard, loadSchedule, loadUsers, loadVirtualMachines, deleteLab, onDismissedIntro, onDismissedRetirementAlert, onDismissedCovidBanner, onDismissedOldV1GpuLabBanner, toggleShowTips, clearUpdateLinkedLmsError, filterByLabAccessType, } = props;
    const { isLoading, currentLabParentId, quotaStatus, isNewLabCreationDisabled, isDeleting, showBlockedLabsBanner, showCovidBanner, showOldGpuLabsGenericBanner, showIntro, showRetirementAlert, currentTenantId, administratorEmailAddress, errors, labs, hintsEnabled, isIntroCheckboxEnabled, isVNextLabs, isVnextLabPermissionPropagating, isVnextAuthErrorPermissionPropagationRelated, isTeamsIntegrationEnabled, isLmsIntegrationEnabled, updateLinkedLmsErrors, } = labListViewModel;
    const intl = useIntl();
    const [showNewLabFlyout, setShowNewLabFlyout] = React.useState(props.showNewLabFlyout);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [showOutageMessage, setShowOutageMessage] = React.useState(true);
    const [labToDelete, setLabToDelete] = React.useState(undefined);
    const { pageTitleStyle, labListCommandBarStyles, secondaryBackgroundColor, messageBarStyles, secondaryCommandBarStyles, accessDropdownStyle, } = getCustomTheme();
    const commandBarStyles = labListCommandBarStyles ?? secondaryCommandBarStyles;
    // ****** IMPORTANT ******Keep these two flags as false until we're ready to show the retirement message******
    const shouldShowRetirementMessage = true;
    const shouldShowRetirementAlert = true;
    if (isLoading) {
        return <LoadingContainer />;
    }
    const onDeleteClicked = (lab) => {
        setLabToDelete(lab);
        setShowDeleteDialog(true);
    };
    const onDeleteDismiss = () => {
        setShowDeleteDialog(false);
        setLabToDelete(undefined);
    };
    const onCardClicked = loadDashboard;
    const renderLab = (lab) => {
        if (!lab) {
            return null;
        }
        return (<LabCard lab={lab} isVNextLab={false} maxUsers={lab.maxUsersInLab} quota={lab.usageQuota.asHours()} onCardClicked={onCardClicked} onDeleteClicked={onDeleteClicked} onScheduleClicked={loadSchedule} onUsersClicked={loadUsers} onVmsClicked={loadVirtualMachines}/>);
    };
    const renderVNextLab = (lab) => {
        if (!lab) {
            return null;
        }
        return (<LabCard lab={lab} isVNextLab={true} maxUsers={!lab.virtualMachineProfile?.sku?.capacity ? 0 : lab.virtualMachineProfile.sku.capacity} quota={moment.duration(lab.virtualMachineProfile.usageQuota).asHours()} onCardClicked={onCardClicked} onDeleteClicked={onDeleteClicked} onScheduleClicked={loadSchedule} onUsersClicked={loadUsers} onVmsClicked={loadVirtualMachines} osType={lab.virtualMachineProfile?.osType} isCustomizable={lab.virtualMachineProfile?.createOption === LabServicesModels.CreateOption.TemplateVM}/>);
    };
    const onNewLab = () => setShowNewLabFlyout(true);
    return (<>
            <div id="lab-list-container">
                <div id="lab-list-content" style={{ backgroundColor: secondaryBackgroundColor }}>
                    <div id="lab-list-header">
                        {shouldShowRetirementMessage && (<MessageBar messageBarType={MessageBarType.severeWarning} styles={messageBarStyles}>
                                <FormattedMessage id="ServiceRetirementLabListMessage" defaultMessage="Azure Lab Services will be retired on June 28th, 2027. Click {RetirementMessageLink} for more information." description="Retirement message displayed in a message bar for lab list." values={{
        RetirementMessageLink: (<Link target="_blank" style={{ paddingLeft: '0px' }} href={'https://aka.ms/azlabs-retirementguide'}>
                                                <FormattedMessage id="ServiceRetirementLabListLink" defaultMessage="here" description="A link to the service retirement public document."/>
                                            </Link>),
    }}/>
                            </MessageBar>)}
                        {!isVNextLabs && showOutageMessage && (<MessageBar messageBarType={MessageBarType.blocked} onDismiss={() => setShowOutageMessage(false)} styles={messageBarStyles} dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)}>
                                <FormattedMessage id="LabListPlannedOutageMessage" defaultMessage="On Feb 8, 2025, we will be updating Lab Account Service between 2:00 pm and 11:00 pm UTC. Please expect downtime for most operations. The service will be available after the update, Refer to the {blogPostLink} for more information." description="Message displayed in a message bar." values={{
        blogPostLink: (<Link target="_blank" href="https://aka.ms/azlabservices-outage3">
                                                <FormattedMessage id="LabListPlannedOutageLinkText" defaultMessage="blog post" description="Text for the {blogPostLink} parameter."/>
                                            </Link>),
    }}/>
                                <FormattedMessage id="LabListVmStuckMessage" defaultMessage="Azure Lab Services is experiencing a performance issue in the UK South region. Please refer to the {vmStuckblogPost} for more information." description="Message displayed in a message bar." values={{
        vmStuckblogPost: (<Link target="_blank" href="https://techcommunity.microsoft.com/blog/AzureLabServicesBlog/azure-lab-services---performance-issues-in-uk-south-region---january-21-2025/4369118">
                                            <FormattedMessage id="LabListVmStuckMessageLinkText" defaultMessage="blog post" description="Text for the {vmStuckblogPost} parameter."/>
                                        </Link>),
    }}/>
                            </MessageBar>)}
                        {errors.size > 0 &&
        errors
            .map((errors) => {
            return errors.map((error, errorIndex) => !(error.code == ErrorCode.AuthorizationFailed &&
                !!isVnextAuthErrorPermissionPropagationRelated) && (<LabErrorBanner key={`${error.id}-${error.failureOperation}`} error={error} clearError={() => clearError(error.id, errorIndex)}/>));
        })
            .toList()}
                        {!!updateLinkedLmsErrors && updateLinkedLmsErrors.size > 0 && (<LinkedLmsUpdateErrors errors={updateLinkedLmsErrors} clearError={clearUpdateLinkedLmsError}/>)}
                        {showBlockedLabsBanner && (<MessageBar messageBarType={MessageBarType.blocked} styles={messageBarStyles}>
                                <FormattedMessage id="LabListBlockedLabsMessage" defaultMessage="The Azure Lab Services outage has been resolved, but you have affected labs that are not recoverable. These labs need to be deleted and recreated. For detailed steps on how to proceed, please see our{blogPostLink}. We are deeply sorry for the inconvenience this has caused." description="Message displayed in a message bar in the lab list when a lab is blocked due to an outage. Note: the missing space before {blogPostLink} is intentional." values={{
        blogPostLink: (<Link target="_blank" href="https://aka.ms/azlabs-outage">
                                                <FormattedMessage id="LabListBlockedMessageLinkText" defaultMessage="outage blog post" description="Text for the {blogPostLink} parameter in LabListBlockedLabsMessage."/>
                                            </Link>),
    }}/>
                            </MessageBar>)}

                        {!isVNextLabs && showOldGpuLabsGenericBanner && (<MessageBar messageBarType={MessageBarType.warning} onDismiss={onDismissedOldV1GpuLabBanner} dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)}>
                                <FormattedMessage {...commonMessages.oldV1GpuLabsGenericWarning} values={{
        gpuOutage: (<Link href={'https://aka.ms/V1GPUIssues'}>
                                                <FormattedMessage id="OldV1GpuLabsWarningLink" defaultMessage="More information." description="Link to documentation describing the GPU outage with older GPU labs."/>
                                            </Link>),
    }}/>
                            </MessageBar>)}
                        {showCovidBanner && (<MessageBar messageBarType={MessageBarType.warning} onDismiss={onDismissedCovidBanner} dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)}>
                                <FormattedMessage {...commonMessages.covidWarning}/>
                            </MessageBar>)}
                        {isVnextLabPermissionPropagating && (<MessageBar messageBarType={MessageBarType.warning}>
                                <FormattedMessage {...commonMessages.permissionPropagationText}/>
                            </MessageBar>)}
                        {quotaStatus === CoreQuotaStatus.Restricted && !administratorEmailAddress && (<MessageBar messageBarType={MessageBarType.blocked}>
                                <FormattedMessage id="NoCoresForSubscriptionLabListMessage" defaultMessage="Your admin needs to request a limit increase in Azure Lab Services for virtual machine cores before you can create a lab. Please contact your Lab Services administator." description="Message displayed on lab list page when customer subscription type has no cores available by default."/>
                            </MessageBar>)}
                        {quotaStatus === CoreQuotaStatus.Restricted && administratorEmailAddress && (<MessageBar messageBarType={MessageBarType.blocked} styles={messageBarStyles}>
                                <FormattedMessage id="NoCoresForSubscriptionLabListMessageWithEmailLink" defaultMessage="Your admin needs to request a limit increase in Azure Lab Services for virtual machine cores before you can create a lab. Please contact your {labAccountAdministrator}." description="Message displayed on lab list page when customer subscription type has no cores available by default. {administrator} is a mailto link to the Lab Services administator with message id 'LabAccountAdministratorLink'." values={{
        administrator: (<Link href={`mailto:${administratorEmailAddress}`}>
                                                <FormattedMessage id="AdministratorLink" defaultMessage="Lab Services administator" description="Link to support email."/>
                                            </Link>),
    }}/>
                            </MessageBar>)}
                        {quotaStatus === CoreQuotaStatus.QuotaExceeded && (<MessageBar messageBarType={MessageBarType.blocked} styles={messageBarStyles}>
                                <FormattedMessage id="QuotaExceededLabListMessage" defaultMessage="Your account has reached its virtual machine cores limit in Azure Lab Services. Before you can create a new lab, you need to {requestLimitIncreaseLink}. You can expect the process to take 1-5 days. Due to high demand, some SKUs may be temporarily unavailable." description="Message displayed on lab list when customer subscription has exceeded its quota. {requestLimitIncreaseLink} is a message with id 'SupportRequestLink' with instructions on how to increase quota for the subscription." values={{
        requestLimitIncreaseLink: (<RequestLimitIncreaseInSentenceLink labParentId={currentLabParentId} tenantId={currentTenantId}/>),
    }}/>
                            </MessageBar>)}
                        {!labs.isEmpty() && (<>
                                <CommandBar styles={commandBarStyles} items={[
        {
            key: 'newLab',
            text: intl.formatMessage(messages.newLab),
            iconProps: { iconName: 'Add' },
            disabled: isNewLabCreationDisabled,
            onClick: onNewLab,
        },
    ]}/>
                            </>)}
                        {isVNextLabs && (<div style={accessDropdownStyle}>
                                <LabAccessDropdown selected={labAccessType} filterByLabAccessType={filterByLabAccessType}/>
                            </div>)}
                        {!labs.isEmpty() && (<h1 style={pageTitleStyle}>
                                <FormattedMessage {...labListMessages.myLabsTitle}/>
                            </h1>)}
                    </div>
                    {!labs.isEmpty() && (<div id="lab-list-body" className="vertical-scrollable-content lab-list">
                            {!isVNextLabs ? (<List items={labs.toArray()} onRenderCell={(lab) => renderLab(lab)} getItemCountForPage={() => labs.size}/>) : (<List items={labs.toArray()} onRenderCell={(lab) => renderVNextLab(lab)} getItemCountForPage={() => labs.size}/>)}
                        </div>)}
                    {labs.isEmpty() && (<div id="lab-list-body">
                            <NoLabsView onNewLab={onNewLab} isNewLabButtonDisabled={isNewLabCreationDisabled} isTeamsIntegrationEnabled={isTeamsIntegrationEnabled} isLmsIntegrationEnabled={isLmsIntegrationEnabled}/>
                        </div>)}
                </div>
            </div>
            {showNewLabFlyout && !isVNextLabs && (<CreateLabFlyoutContainer labParentId={currentLabParentId} onDismiss={() => {
        setShowNewLabFlyout(false);
        returnToLabList();
    }} onCreatedRoute={Routes.Template}/>)}
            {showNewLabFlyout && isVNextLabs && (<VNext_CreateLabFlyoutContainer labParentId={currentLabParentId} onDismiss={() => {
        setShowNewLabFlyout(false);
        returnToLabList();
    }} onCreatedRoute={Routes.Template}/>)}
            {showDeleteDialog && (<DeleteLabDialog onDismiss={onDeleteDismiss} deleteLab={deleteLab} lab={labToDelete} isSubmitting={isDeleting}/>)}
            {shouldShowRetirementAlert && showRetirementAlert && (<RetirementNotification onDismiss={onDismissedRetirementAlert}/>)}
            {showIntro && (<LabListIntroduction hintsEnabled={hintsEnabled} checkboxEnabled={isIntroCheckboxEnabled} onDismiss={onDismissedIntro} toggleShowTips={toggleShowTips}/>)}
        </>);
};
export default LabList;
